import React from "react";
import { Row, Col, Table, Button } from "antd";
import PageTitleDetails from "../../components/common/page-title-detail";
import ListFilters from "../../components/common/list-filters";
import CommonListBase from "../../base/common-list-base";
import {
  currencyFormatter,
  dateFormatter,
} from "../../components/common/common-formatter";
import { ExportOutlined } from "@ant-design/icons";

class SalesMonthlyList extends CommonListBase {
  constructor(props) {
    super(props);
    this.state = {
      listReq: this.getListReq(),
      data: [],
      pagination: {
        current: 1,
        pageSize: 100,
      },
      loading: false,
      columns: [],
      columnsDefault: [],
      filterColumns: [],
      sorting: [],
      screen: "dashboard-sales-drilldown",
      isDrawer: false,
      record: "",
      isEditMode: false,
    };
  }

  setColumns = () => {
    let columns = [
      {
        title: "IID",
        dataIndex: "inventory_code",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "City",
        dataIndex: "city_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Location",
        dataIndex: "location",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "From",
        dataIndex: "campaign_start_date",
        render: (text) => dateFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "To",
        dataIndex: "campaign_end_date",
        render: (text) => dateFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Days",
        dataIndex: "campaign_days",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Monthly Rate",
        dataIndex: "monthly_rate",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 120,
      },
      {
        title: "Amount",
        dataIndex: "amount",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Customer",
        dataIndex: "customer_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Display",
        dataIndex: "display_name",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Employee",
        dataIndex: "employee",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
      },
      {
        title: "Invoice Date",
        dataIndex: "invoice_date",
        render: (text) => dateFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 120,
      },
      {
        title: "Invoice Number",
        dataIndex: "invoice_number",
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 120,
      },
      {
        title: "Realised (billed)",
        dataIndex: "unreal_sales",
        render: (text) => currencyFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 120,
      },
      {
        title: "Unrealised (unbilled)",
        dataIndex: "real_sales",
        render: (text) => currencyFormatter(text),
        sorter: true,
        sortDirections: ["ascend", "descend", "ascend"],
        width: 148,
      },
    ];

    const filterColumns = [
      {
        columnName: "inventory_code",
        displayName: "IID",
        default: true,
      },
      { columnName: "city_name", displayName: "City" },
      { columnName: "location", displayName: "Location" },
      {
        columnName: "campaign_start_date",
        displayName: "From",
        hideFilter: true,
      },
      { columnName: "campaign_end_date", displayName: "To", hideFilter: true },
      { columnName: "campaign_days", displayName: "Days", hideFilter: true },
      { columnName: "monthly_rate", displayName: "Monthly Rate" },
      { columnName: "amount", displayName: "Amount" },
      { columnName: "customer_name", displayName: "Customer" },
      { columnName: "display_name", displayName: "Display" },
      { columnName: "employee", displayName: "Employee" },
      { columnName: "invoice_date", displayName: "Invoice Date" },
      { columnName: "invoice_number", displayName: "Invoice Number" },
      { columnName: "unreal_sales", displayName: "Realised (billed)" },
      { columnName: "real_sales", displayName: "Unrealised (unbilled)" },
    ];

    this.setState({ columnsDefault: columns, filterColumns }, () =>
      this.setDefaultColumns(columns)
    );
  };

  getYear = () => {
    const now = new Date();
    const year = now.getFullYear();
    const financialYear = now.getMonth() >= 3 ? year : year - 1;
    return financialYear.toString().slice(-2);
  };

  handleMonthClick = (month) => {
    this.props.handleDetails(month);
  };

  handleBack = () => {
    this.props.history.goBack();
  };

  getMonthDates = (month) => {
    const monthsRange = {
      apr: "durationfrom=2024-04-01&durationto=2024-04-30",
      may: "durationfrom=2024-05-01&durationto=2024-05-31",
      jun: "durationfrom=2024-06-01&durationto=2024-06-30",
      jul: "durationfrom=2024-07-01&durationto=2024-07-31",
      aug: "durationfrom=2024-08-01&durationto=2024-08-31",
      sep: "durationfrom=2024-09-01&durationto=2024-09-30",
      oct: "durationfrom=2024-10-01&durationto=2024-10-31",
      nov: "durationfrom=2024-11-01&durationto=2024-11-30",
      dec: "durationfrom=2024-12-01&durationto=2024-12-31",
      jan: "durationfrom=2025-01-01&durationto=2025-01-31",
      feb: "durationfrom=2025-02-01&durationto=2025-02-28",
      mar: "durationfrom=2025-03-01&durationto=2025-03-31",
      total: "durationfrom=2024-04-01&durationto=2025-03-31",
    };

    return monthsRange[month];
  };

  getListReq = () => {
    let req = "dashboard/sales/details?";
    const { month, type } = this?.props?.match?.params;
    return req + this.getMonthDates(month) + "&";
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.getResults({ pagination });
    this.setColumns();
  }

  render() {
    const { data, columns, pagination, loading, filterColumns, screen } =
      this.state;

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <PageTitleDetails
              titleText={"Monthly Sales"}
              isBackBtn="true"
              handleBack={this.handleBack}
            />

            <div className="page-title-custom-btn sales-dtl-btns">
              <Button
                type="primary"
                // onClick={this.handleExport}
                icon={<ExportOutlined />}
                className="icon-btn"
              >
                Excel Export
              </Button>
            </div>

            <ListFilters
              items={filterColumns}
              handleSettings={this.handleSettings}
              handleFilters={this.handleFilters}
              key={filterColumns}
              screen={screen}
            />

            <Table
              columns={columns}
              rowKey={(record) => record?.id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
              bordered
              scroll={{ x: 400 }}
              size="small"
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default SalesMonthlyList;
