import React from "react";
import { Row, Col, Table } from "antd";
import CommonListBase from "../../base/common-list-base";
import { currencyFormatter } from "../../components/common/common-formatter";
import ListFiltersDashboard from "../common/list-filters-dashboard";

class SalesCityList extends CommonListBase {
  state = {
    listReq: "dashboard/sales?resultgroup=city&durationGroup=current_finyear&",
    delReq: "sistercompany/delete?payout_estimate_id=",
    dtlReq: "sistercompany",
    data: [],
    pagination: {
      current: 1,
      pageSize: 10,
    },
    loading: false,
    columns: [],
    columnsDefault: [],
    filterColumns: [],
    sorting: [],
    screen: "dashboard-city-list",
    isDrawer: false,
    record: "",
    isEditMode: false,
  };

  setColumns = () => {
    let columns = [
      {
        title: "City Name",
        dataIndex: "type",
      },
      {
        title: "April " + this.getYear(),
        dataIndex: "apr",
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: "May " + this.getYear(),
        dataIndex: "may",
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: "June " + this.getYear(),
        dataIndex: "jun",
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: "July " + this.getYear(),
        dataIndex: "jul",
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: "August " + this.getYear(),
        dataIndex: "aug",
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: "September " + this.getYear(),
        dataIndex: "sep",
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: "October " + this.getYear(),
        dataIndex: "oct",
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: "November " + this.getYear(),
        dataIndex: "nov",
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: "December " + this.getYear(),
        dataIndex: "dec",
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: "January " + (Number(this.getYear()) + 1),
        dataIndex: "jan",
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: "February " + (Number(this.getYear()) + 1),
        dataIndex: "feb",
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: "March " + (Number(this.getYear()) + 1),
        dataIndex: "mar",
        render: (text) => currencyFormatter(parseInt(text)),
      },
      {
        title: "Total",
        dataIndex: "total",
        render: (text) => currencyFormatter(parseInt(text)),
      },
    ];

    const filterColumns = [
      {
        columnName: "city_name",
        displayName: "City",
        default: true,
      },
    ];

    this.setState({ columnsDefault: columns, filterColumns }, () =>
      this.setDefaultColumns(columns)
    );
  };

  dataFormating = (data) => {
    let newD = data.map((x, key) => {
      const monthlySales = {};
      x?.monthly_sales?.forEach((y) => {
        monthlySales[y.month] = y.total_sales;
      });

      const totalSales = Object.values(monthlySales).reduce(
        (sum, sales) => sum + (sales || 0),
        0
      );

      return {
        id: key,
        type: x.city_name,
        apr: monthlySales[4],
        may: monthlySales[5],
        jun: monthlySales[6],
        jul: monthlySales[7],
        aug: monthlySales[8],
        sep: monthlySales[9],
        oct: monthlySales[10],
        nov: monthlySales[11],
        dec: monthlySales[12],
        jan: monthlySales[1],
        feb: monthlySales[2],
        mar: monthlySales[3],
        total: totalSales,
      };
    });

    return newD;
  };

  getYear = () => {
    const now = new Date();
    const year = now.getFullYear();
    const financialYear = now.getMonth() >= 3 ? year : year - 1;
    return financialYear.toString().slice(-2);
  };

  componentDidMount() {
    const { pagination } = this.state;
    this.getResults({ pagination });
    this.setColumns();
  }

  render() {
    const { data, columns, pagination, loading, filterColumns, screen } =
      this.state;

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <ListFiltersDashboard
              items={filterColumns}
              handleSettings={this.handleSettings}
              handleFilters={this.handleFilters}
              key={filterColumns}
              screen={screen}
            />

            <Table
              columns={columns}
              rowKey={(record) => record?.id}
              dataSource={this.dataFormating(data)}
              pagination={pagination}
              loading={loading}
              onChange={this.handleTableChange}
              bordered
              scroll={{ x: 400 }}
              size="small"
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default SalesCityList;
